export const salaryDriverColNm = {
  SalAmnt: "เงินเดือน",
  HousAmnt: "รายได้1",
  TripAmnt: "ค่าเที่ยว",
  MobAmnt: "ค่าโทรศัพท์",
  LivAmnt: "รายได้2",
  BonsAmnt: "โบนัส",
  HdayRefnAmnt: "รายได้3",
  OthAmnt: "รายได้อื่นๆ",
  OthDscp: "รายละเอียดรายได้อื่นๆ",
  DeLoanAmnt: "หักเงินกู้",
  DeElecAmnt: "หักค่าไฟ",
  DeSecDepAmnt: "หักค่าประกันรถ",
  DeSSPrct: "ปกส.(%)",
  DeTfcFineAmnt: "หักค่าปรับ",
  DeEduLoanAmnt: "หัก กยศ./กรอ.",
  DeSSAmnt: "หัก ปกส.",
  AdvAmnt: "สำรองจ่าย",
  TrnsAmnt: "เบิกล่วงหน้า",
  DeOthAmnt: "หักอื่นๆ",
  DeOthDscp: "รายละเอียดหักอื่นๆ",
  DeTaxAmnt: "หัก ภงด.1",
  TotalInc: "รวมรายได้",
  TotalDedc: "รวมรายจ่าย",
  LeftAmnt: "เงินขาด-เกิน",
  GrndTotal: "คงเหลือ"
}