import { Dialog, DialogContent, TextField } from "@mui/material"
import { DesktopDatePicker } from "@mui/x-date-pickers-pro"
import { branchApiNm } from "branch/constant/branchApiNm"
import { alertError } from "components/Alert"
import { BoxFR } from "components/BoxCustom"
import ComboBox from "components/ComboBox"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import dayjs from "dayjs"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { bahtProps } from "utils/bahtProps"

const initDialogData = {
  DrvId: null,
  SalDte: dayjs().startOf('month'),
  SalAmnt: ""
}
const AddDriverSalaryDialog = ({ exDrvIds, dialogOpen, setDialogOpen, lastFilter, onFinish }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initDialogData })

  // const drvComboData = useMemo(() => {
  //   const drvIds = exDrvIds || []
  //   return msData.driverOnlyCombo.filter(drv => !drvIds.includes(drv.id))
  // }, [exDrvIds, msData.driverOnlyCombo])

  const handleDialogOk = useCallback(() => {
    if (dialogData.SalAmnt === '') {
      alertError("กรุณากรอกเงินเดือน")
      return
    }
    const postData = {
      DrvIds: [dialogData.DrvId],
      SalDte: dayjs(dialogData.SalDte).format("YYYY-MM-01"),
      ColNm: "SalAmnt",
      ColVal: dialogData.SalAmnt,
      getArgs: {
        ...lastFilter,
        SalDte: dayjs(lastFilter.SalDte).format("YYYY-MM-01")
      }
    }
    ax.post(branchApiNm.inssertUpdateDriverSalary, postData).then(value => {
      if (value.data) {
        onFinish(value.data)
        setDialogOpen(false)
      }
    })

  }, [ax, dialogData, setDialogOpen, onFinish, lastFilter])

  useEffect(() => {
    if (!dialogOpen) {
      setDialogData({ ...initDialogData })
    }
  }, [dialogOpen])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="เพิ่มรายชื่อพนักงานขับรถ"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFR pt={2} justifyContent="center">
          <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label={"พนักงานขับรถ"} required={true}
            selectedId={dialogData.DrvId}
            setSelectedId={(id) => setDialogData(o => ({ ...o, DrvId: id }))}
          />
          <DesktopDatePicker label="ประจำเดือน" value={dialogData.SalDte}
            openTo="month" views={["year", "month"]} disableMaskedInput
            renderInput={(params) => <TextField {...params} sx={{ width: 180 }} size="small"
              required={true} error={!dialogData.SalDte} />}
            onChange={(newValue) => setDialogData(o => ({ ...o, SalDte: newValue }))} />
          <TextField {...bahtProps} size="small" label="เงินเดือน" value={dialogData.SalAmnt} sx={{ width: 160 }}
            onChange={(e) => setDialogData(o => ({ ...o, SalAmnt: e.target.value }))} type="number" 
            required={true} error={dialogData.SalAmnt === ''}/>
        </BoxFR>
      </DialogContent>
      <DialogFooter okText={"เพิ่มข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default React.memo(AddDriverSalaryDialog)