import { jsPDF } from "jspdf";
import { numberFormat } from "utils";
// import { toBahtText } from "utils/toBahtText";
import "resource/font/THSarabun-normal"
import "resource/font/THSarabun-bold"
import { printHeader } from "./pirntHeader";
import dayjs from "dayjs";
import { salaryDriverColNm } from "branch/pages/DriverSalary/constant";
import { blue, yellow } from "@mui/material/colors";


const salColNm = {
  ...salaryDriverColNm,
  SumInc: "รวมรายได้",
  SumDeduct: "รวมรายจ่าย"
}
export const printDriverSalary = ({ dialogData, dataTable, msData }) => {
  console.log("printDriverSalary::", dialogData, dataTable, msData)
  const companyData = msData.serverData.CompanyData
  const startY = 0.1
  const startX = 0.5
  const pageSize = [8.3, 11.7];
  const lineHeight = 0.21
  const contentSize = 14;
  let yPos = startY
  let xPos = startX
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "in",
    format: pageSize
  });
  doc.setLineWidth(0.01)
  doc.setFont("THSarabun")


  // const totalPage = Math.ceil(dataTable.length / MAX_PAGE_SIZE)
  // console.log("dataTable.length::", dataTable.length)
  // console.log("totalPage::", totalPage)
  let pageNo = 1
  // let maxRow = MAX_PAGE_SIZE

  doc.setFont("THSarabun")
  if (pageNo !== 1) {
    doc.addPage(pageSize, "portrait")
  }
  xPos = startX
  yPos = startY
  yPos = printHeader({ jsPDFDoc: doc, companyData, startX: xPos, startY: yPos })

  //---------------------------------draw top right -------------------------------------
  yPos = startY + 0.2
  doc.setFont("THSarabun", "bold")
  doc.setFontSize(20)
  doc.text("รายละเอียดเงินเดือน", 9.8, yPos, { align: "center" })

  doc.setFont("THSarabun", "bold")
  doc.setFontSize(contentSize)
  const lineHeightTopRight = lineHeight + lineHeight / 5
  const yPosTopRight = yPos + lineHeightTopRight
  const xPosTopRight = 8.5
  xPos = xPosTopRight
  yPos = yPosTopRight

  doc.text("ประจำเดือน : ", xPos, yPos)
  yPos += lineHeightTopRight
  doc.text("พนักงาน: ", xPos, yPos)
  yPos += lineHeightTopRight
  doc.setFont("THSarabun", "normal")
  yPos = yPosTopRight
  xPos += 0.8
  doc.text(dayjs(dialogData.SalDte).format("MMMM YYYY"), xPos, yPos)
  yPos += lineHeightTopRight
  doc.setFontSize(contentSize + 3)
  doc.setFont("THSarabun", "bold")
  doc.text(dialogData.DrvNm, xPos, yPos)
  doc.setFontSize(contentSize)
  doc.setFont("THSarabun", "normal")
  yPos += lineHeightTopRight

  yPos += lineHeight + 0.15

  const startTableX = startX - 0.15
  const numberColWidth = 1.6
  const gap = 0.2
  const summaryTableWidth = numberColWidth*2 + gap +0.2
  const drawNumber = (name, xPos, yPos) => {
    doc.text(salColNm[name] || "-", xPos, yPos)
    doc.text(`${numberFormat(+dialogData[name])} บาท`, xPos + numberColWidth, yPos, { align: "right" })
  }

  const drawSummaryTable = (startTableX, startTableY) => {
    let tableYPos = startTableY - lineHeight
    let tableXPos = startTableX - 0.1
    doc.setFillColor(blue[100])
    doc.rect(tableXPos, tableYPos, summaryTableWidth, lineHeight + 0.1, "F")
    doc.line(tableXPos, tableYPos, tableXPos + summaryTableWidth, tableYPos)
    tableYPos += lineHeight + 0.1
    doc.line(tableXPos, tableYPos, tableXPos + summaryTableWidth, tableYPos)
    tableYPos += (lineHeight * 4) + 0.1

    doc.setFillColor(yellow[100])
    doc.rect(tableXPos, tableYPos, summaryTableWidth, lineHeight + 0.1, "F")
    doc.line(tableXPos, tableYPos, tableXPos + summaryTableWidth, tableYPos)
    tableYPos += lineHeight + 0.1
    doc.line(tableXPos, tableYPos, tableXPos + summaryTableWidth, tableYPos)
    doc.line(tableXPos, startTableY - lineHeight, tableXPos, tableYPos)
    doc.line(tableXPos + summaryTableWidth, startTableY - lineHeight, tableXPos + summaryTableWidth, tableYPos)
  }
  const startYData = yPos
  xPos = startTableX
  yPos = startYData
  drawSummaryTable(xPos, yPos)
  doc.setFont("THSarabun", "bold")
  doc.text("รายได้", xPos + (numberColWidth*2+gap) / 2, yPos, { align: "center" })
  yPos += lineHeight + 0.1
  const startInTableData = yPos
  doc.setFont("THSarabun", "normal")
  drawNumber("SalAmnt", xPos, yPos)
  yPos += lineHeight
  drawNumber("MobAmnt", xPos, yPos)
  yPos += lineHeight
  drawNumber("HousAmnt", xPos, yPos)
  yPos += lineHeight
  drawNumber("LivAmnt", xPos, yPos)
  yPos = startInTableData
  xPos += numberColWidth + gap
  drawNumber("BonsAmnt", xPos, yPos)
  yPos += lineHeight
  drawNumber("HdayRefnAmnt", xPos, yPos)
  yPos += lineHeight
  drawNumber("OthAmnt", xPos, yPos)

  yPos = startYData + (lineHeight * 5) + 0.2
  doc.setFont("THSarabun", "bold")
  doc.text("รวมรายได้", xPos -numberColWidth, yPos)
  doc.text(`${numberFormat(dialogData.SumInc)} บาท`, xPos + numberColWidth, yPos, { align: "right" })
  if(dialogData.OthDscp){
    doc.setFont("THSarabun", "bold")
    doc.setFontSize(contentSize -2)
    doc.text(`*รายได้อื่นๆ : ${dialogData.OthDscp}`, xPos +numberColWidth, yPos +lineHeight +0.04, { align: "right" })
    doc.setFontSize(contentSize)
  }

  const tableYPos = yPos += lineHeight

  xPos = startTableX + 3.8
  yPos = startYData
  drawSummaryTable(xPos, yPos)
  doc.setFont("THSarabun", "bold")
  doc.text("รายจ่าย", xPos + (numberColWidth*2+gap) / 2, yPos, { align: "center" })
  yPos += lineHeight + 0.1
  doc.setFont("THSarabun", "normal")
  drawNumber("DeLoanAmnt", xPos, yPos)
  yPos += lineHeight
  drawNumber("DeElecAmnt", xPos, yPos)
  yPos += lineHeight
  drawNumber("DeSecDepAmnt", xPos, yPos)
  yPos += lineHeight
  drawNumber("DeEduLoanAmnt", xPos, yPos)
  yPos = startInTableData
  xPos += numberColWidth + gap
  drawNumber("DeSSAmnt", xPos, yPos)
  yPos += lineHeight
  drawNumber("DeTaxAmnt", xPos, yPos)
  yPos += lineHeight
  drawNumber("DeTfcFineAmnt", xPos, yPos)
  yPos += lineHeight
  drawNumber("DeOthAmnt", xPos, yPos)

  yPos = startYData + (lineHeight * 5) + 0.2
  doc.setFont("THSarabun", "bold")
  doc.text("รวมรายจ่าย", xPos -numberColWidth, yPos)
  doc.text(`${numberFormat(dialogData.SumDeduct)} บาท`, xPos + numberColWidth, yPos, { align: "right" })
  if(dialogData.DeOthDscp){
    doc.setFont("THSarabun", "bold")
    doc.setFontSize(contentSize -2)
    doc.text(`*หักอื่นๆ : ${dialogData.DeOthDscp}`, xPos +numberColWidth, yPos +lineHeight +0.04, { align: "right" })
    doc.setFontSize(contentSize)
  }




  xPos = startTableX + 7.6
  yPos = startYData
  drawSummaryTable(xPos, yPos)
  doc.setFont("THSarabun", "bold")
  doc.text("สรุปยอด", xPos + (numberColWidth*2+gap) / 2, yPos, { align: "center" })
  yPos += lineHeight + 0.1
  doc.setFont("THSarabun", "normal")
  xPos += (numberColWidth+gap)/2
  drawNumber("SumInc", xPos, yPos)
  yPos += lineHeight
  drawNumber("SumDeduct", xPos, yPos)
  yPos += lineHeight
  drawNumber("LeftAmnt", xPos, yPos)

  yPos = startYData + (lineHeight * 5) + 0.2
  doc.setFont("THSarabun", "bold")
  doc.text("ยอดคงเหลือ", xPos, yPos)
  doc.text(`${numberFormat(dialogData.SumInc - dialogData.SumDeduct + +(dialogData.LeftAmnt || 0))} บาท`, xPos + numberColWidth, yPos, { align: "right" })

  xPos = startTableX
  yPos = tableYPos + 0.18

  doc.text("รายละเอียดวิ่งงาน", xPos, yPos)
  //-------------------------------------- Box Header --------------------------------------------------------
  const colNoW = 0.35
  const colAptTmW = 0.45
  const colContNoW = 1
  const colLocW = 1.9
  const colTripW = 0.7
  const colOverNW = 0.7
  const colLiftW = 0.7
  const colDepotW = 0.7
  const colTollW = 0.7
  const colGateW = 0.7
  const colOthW = 0.7
  const colTotalExpW = 0.8
  const colTotalOut = 0.8
  const colGrndTotal = 0.8
  const dataBoxW = colNoW + colAptTmW + colContNoW + colLocW + colTripW + colOverNW + colLiftW + colDepotW + colGateW 
  + colTollW + colOthW + colTotalExpW + colTotalOut + colGrndTotal

  yPos += lineHeight - 0.1
  doc.setFillColor(blue[100])

  doc.rect(xPos, yPos, dataBoxW, lineHeight + 0.1, "F")
  doc.line(xPos, yPos, xPos + dataBoxW, yPos)
  // doc.lines([[dataBoxW, 0], [0, lineHeight * 2], [dataBoxW * -1, 0], [0, lineHeight * 2 * -1]], startTableX, yPos, [1, 1], 'F', true)
  // doc.line(startTableX, yPos, startTableX + dataBoxW, yPos)
  // doc.lines([[dataBoxW, 0], [0, dataBoxH], [dataBoxW * -1, 0], [0, dataBoxH * -1]], startTableX, yPos, [1, 1], 'S', true)
  // xPos = startTableX + invNoColW
  // doc.line(xPos, yPos, xPos, yPos + dataBoxH)
  // xPos += invDteColW
  // doc.line(xPos, yPos, xPos, yPos + dataBoxH)
  // xPos += ownRcptColW
  // doc.line(xPos, yPos, xPos, yPos + dataBoxH)
  // xPos += cusRcptColW
  // doc.line(xPos, yPos, xPos, yPos + dataBoxH + lineHeight * 2)
  //------------------------------------- Text Header ----------------------------------------------------------
  yPos += lineHeight
  xPos = startTableX
  const drawHeader = (label, width) => {
    doc.text(label, xPos + width / 2, yPos, { align: "center" })
    xPos += width
  }
  const drawText = (text, width) => {
    doc.text(text, xPos + 0.1, yPos, )
    xPos += width
  }
  // doc.text("Job No.", xPos + jobNoColW / 2, yPos, { align: "center" })
  // xPos += jobNoColW
  drawHeader("No.", colNoW)
  drawHeader("วันที่", colAptTmW)
  drawHeader("หมายเลขตู้", colContNoW)
  drawHeader("สถานที่", colLocW)
  drawHeader("ค่าเที่ยว", colTripW)
  drawHeader("ค้างคืน", colOverNW)
  drawHeader("ยกตู้", colLiftW)
  drawHeader("ค่าลาน", colDepotW)
  drawHeader("ผ่านท่า", colGateW)
  drawHeader("ทางด่วน", colTollW)
  drawHeader("ค่าอื่นๆ", colOthW)
  drawHeader("รวมค่าใช้จ่าย", colTotalExpW)
  drawHeader("เบิกล่วงหน้า", colTotalOut)
  drawHeader("เงินขาด-เกิน", colGrndTotal)
  yPos += 0.1
  doc.line(startTableX, yPos, startTableX + dataBoxW, yPos)
  //------------------------------------- Data ----------------------------------------------------------
  const drawNumberTable = (number, width) => {
    doc.text(`${numberFormat(number)}`, xPos + width, yPos, { align: "right" })
    xPos += width
  }
  doc.setFont("THSarabun", "normal")
  yPos += lineHeight
  xPos = startTableX
  for (const data of dataTable) {
    xPos = startTableX
    if (data.id === 0) {
      doc.setFont("THSarabun", "bold")
      doc.setFillColor(yellow[100])
      doc.rect(startTableX, yPos - 0.1, dataBoxW, lineHeight + 0.1, "F")
      doc.line(startTableX, yPos - 0.1, startTableX + dataBoxW, yPos - 0.1)
      yPos += 0.1
    }
    drawHeader(`${data.No || ""}.`, colNoW)
    drawHeader(`${data.AptTm ? dayjs(data.AptTm).format("DD/MM") : "-"}`, colAptTmW)
    drawText(`${data.ContNo || "-"}`, colContNoW)
    drawText(`${data.Loc || "-"}`, colLocW)
    drawNumberTable(+data.E83, colTripW)
    drawNumberTable(+data.E86, colOverNW)
    drawNumberTable(+data.E3, colLiftW)
    drawNumberTable(+data.E5, colDepotW)
    drawNumberTable(+data.E4, colGateW)
    drawNumberTable(+data.E84, colTollW)
    drawNumberTable(+data.OthAmnt, colOthW)
    drawNumberTable(+data.ExpTotal, colTotalExpW)
    drawNumberTable(+data.TotalOut, colTotalOut)
    drawNumberTable(+(data.ExpTotal || 0) - data.TotalOut, colGrndTotal)
    yPos += lineHeight
  }

  doc.line(startTableX, yPos - 0.1, startTableX + dataBoxW, yPos - 0.1)

  yPos += (lineHeight * 2) + 0.1

  doc.setFont("THSarabun", "normal")
  const signWidth = 1.5
  const startsignX = startTableX + 0.6
  const col1 = startsignX
  const col2 = startsignX + dataBoxW * 1/4
  const col3 = startsignX + dataBoxW * 1/2
  const col4 = startsignX + dataBoxW * 3/4

  doc.line(col1, yPos, col1 + signWidth, yPos)
  doc.line(col2, yPos, col2 + signWidth, yPos)
  doc.line(col3, yPos, col3 + signWidth, yPos)
  doc.line(col4, yPos, col4 + signWidth, yPos)
  yPos += lineHeight
  doc.text("ผู้จัดทำ", col1 + signWidth / 2, yPos, { align: "center" })
  doc.text("ผู้ตรวจสอบ", col2 + signWidth / 2, yPos, { align: "center" })
  doc.text("ผู้ตรวจสอบ", col3 + signWidth / 2, yPos, { align: "center" })
  doc.text("ผู้ตรวจสอบ", col4 + signWidth / 2, yPos, { align: "center" })

  doc.output('dataurlnewwindow');
}