import { Box, Dialog, DialogContent, MenuItem, TextField } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { initDriverSalaryMasterFilterData } from "./initData"
import SearchButton from "components/buttons/SearchButton"
import ClearButton from "components/buttons/ClearButton"
import { branchApiNm } from "branch/constant/branchApiNm"
import { addIdForDataGrid } from "utils"
import { dirverSalaryMasterColumns } from "./columns"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import TextFieldDialog from "components/dialog/TextFieldDialog"
import { salaryDriverColNm } from "./constant"

let selectedRow = null
let selectedField = null
let lastFilter = null
const DriverSalaryMasterDialog = ({ dialogOpen, setDialogOpen }) => {

  const { ax } = useContext(GlobalStateContext)

  const [filterData, setFilterData] = useState({ ...initDriverSalaryMasterFilterData })
  const [dataTable, setDataTable] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [dialogTextFieldOpen, setDialogTextFieldOpen] = useState(false)

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getDriverSalaryMaster, filter).then(value => {
      if (value.data) {
        lastFilter = filter
        setDataTable(addIdForDataGrid(value.data, 'DrvId'))
      }
    })
  }, [ax])

  const handleCellClick = useCallback((params) => {
    if (["SalAmnt", "MobAmnt", "HousAmnt", "LivAmnt", "DeLoanAmnt", "DeElecAmnt", "DeSecDepAmnt", "DeSSPrct"].includes(params.field)) {
      selectedRow = params.row
      selectedField = params.field
      setDialogTextFieldOpen(true)
    }
  }, [])

  const onTextFieldDialogFinish = useCallback((value) => {
    const postData = {
      DrvIds: selectionModel.includes(selectedRow.id) ? selectionModel : [selectedRow.id],
      ColNm: selectedField,
      ColVal: value,
      getArgs: lastFilter
    }
    ax.post(branchApiNm.inssertUpdateDriverSalaryMaster, postData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, 'DrvId'))
      }
    }, [])
  }, [ax, selectionModel,])

  useEffect(() => {
    if (dialogOpen) {
      getData({ ...initDriverSalaryMasterFilterData })
    } else {
      setDataTable([])
    }
  }, [dialogOpen, ax, getData])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="กำหนดค่าเริ่มต้น พขร."
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} >
          <BoxFR>
            <Box flex={1} />
            <TextField label="สถานะ พขร" size="small" select value={filterData.IsActv || ""}
              onChange={e => setFilterData(o => ({ ...o, IsActv: e.target.value || null }))}>
              <MenuItem value={null}>ไม่ระบุ</MenuItem>
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={0}>Inactive</MenuItem>
            </TextField>
            <TextField label="ชื่อ-นามสกุล" size="small" value={filterData.DrvNm || ""}
              onChange={e => setFilterData(o => ({ ...o, DrvNm: e.target.value || null }))} />
            <SearchButton onClick={() => { getData(filterData) }} />
            <ClearButton onClick={() => { setFilterData({ ...initDriverSalaryMasterFilterData }) }} />
          </BoxFR>
          <Box height={650}>
            <DataGridCellExpand
              checkboxSelection
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              hideFooter
              rows={dataTable}
              columns={dirverSalaryMasterColumns}
              onCellClick={handleCellClick}
            />
          </Box>
        </BoxFC>
        <TextFieldDialog title={`ระบุ ${salaryDriverColNm[selectedField]}`} label="จำนวน" type="number"
          oldData={selectedRow ? selectedRow[selectedField] : null} dialogOpen={dialogTextFieldOpen}
          setDialogOpen={setDialogTextFieldOpen} onFinish={onTextFieldDialogFinish} />
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(DriverSalaryMasterDialog)