import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogContent, TextField } from "@mui/material"
import { DialogHeader, DialogFooter } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";

const TextFieldDaialog = ({ oldData, title, label, type, setDialogOpen, dialogOpen, onFinish, multiline=false, rows=1, sx }) => {
  const [textData, setTextData] = useState("")

  useEffect(() => {
    if (dialogOpen) {
      if (oldData) {
        setTextData(oldData)
      } else {
        setTextData("")
      }
    }
  }, [oldData, dialogOpen])

  const handleUpdateColumn = () => {
    onFinish(textData)
    setDialogOpen(false)
  }
  // console.log("in updateDateTime::")
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' >
      <DialogHeader  handleDialogClose={() => setDialogOpen(false)}
        title={title}
        onDeleteClick={null}
      />
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={2} mt={2}>
          <Box display="flex" gap={2} justifyContent="center">
            <TextField size="small" label={label} value={textData} type={type || "text"}
              onChange={e=>{setTextData(e.target.value)}} multiline={multiline} rows={rows} sx={sx}/>
          </Box>
        </Box>
      </DialogContent>
      <DialogFooter okText={"แก้ไขข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleUpdateColumn} />
    </Dialog>
  )
}

export default React.memo(TextFieldDaialog)