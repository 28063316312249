import { Box, Button, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { JobOrderTransferDialogV2Context } from "../../JobOrderTransferDialogV2";
import { itmColumns } from "../columns";
import { ReceiptRounded } from "@mui/icons-material";
import ReimbursementDialog from "pages/Menu1/PettyCash/ReimbursementDialog";


const PCItemsData = () => {

  const [dialogReimbursementOpen, setDialogReimbursementOpen] = useState(false)
  const { pcItemData, handleShowPCTrnsDetail, joSelection, onFinishToJobOrderTransfer, drvId, joItem } 
    = useContext(JobOrderTransferDialogV2Context)

  const handleAddReimbursement = useCallback(() => {
    setDialogReimbursementOpen(true)
  }, [])

  const itmColumnsMemo = useMemo(() => itmColumns(handleShowPCTrnsDetail)
    , [handleShowPCTrnsDetail])


  const drvIdObjMemo = useMemo(() => ({ DrvId: drvId }), [drvId])
  return (
    <BoxFC sx={{ gap: 1 }} height="100%" width={520}>
      <BoxFR sx={{ alignItems: "stretch" }}>
        <Typography variant="h6">ค่าใช้จ่ายใบงาน</Typography>
        <Button variant="contained" size="small" disabled={joSelection.length === 0}
          onClick={handleAddReimbursement}><ReceiptRounded sx={{ mr: 1 }} /> เบิกตามใบเสร็จ</Button>
      </BoxFR>
      <Box flex={1} width="100%">
        <DataGridCellExpand
          density="compact"
          hideToolbar
          hideFooter
          rows={pcItemData}
          columns={itmColumnsMemo}
          onRowDoubleClick={handleShowPCTrnsDetail}
        />
      </Box>
      <ReimbursementDialog
        dialogOpen={dialogReimbursementOpen}
        setDialogOpen={setDialogReimbursementOpen}
        onFinish={onFinishToJobOrderTransfer}
        getFn= "getJobOrderByDriverV2"
        getArgs={drvIdObjMemo} 
        joData={joItem}
        usrAccId={drvId}/>
    </BoxFC>
  );
}

export default React.memo(PCItemsData);