import { orange } from "@mui/material/colors";

export const rowHighlightOrange = {
  "& .MuiDataGrid-row.Mui-selected": {bgcolor: orange[100], "&:hover": {bgcolor: orange[200]}},
}

export const initJOItem = {
  JobOrdId: null,
  FinDte: null,
  DrvId: null,
  ArvTm: null,
  LevTm: null,
  TakeArvTm: null,
  TakeLevTm: null,
  ContNo: "",
  SealNo: "",
  FullWt: "",
  TareWt: "",
  Rmk: ""
}