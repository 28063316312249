export const clearFilterData = {
  AptTmSt: null,
  AptTmEd: null,
  FinDteSt: null,
  FinDteEn: null,
  DrvId: null,
  SalDte: null,
}

export const initFilterData = {
  ...clearFilterData
}