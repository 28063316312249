import { Box, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { JobOrderTransferDialogV2Context } from "../../JobOrderTransferDialogV2";
import { jobOrderColumns } from "../columns";
import { initJOItem, rowHighlightOrange } from "../../initData";
import { addIdForDataGrid } from "utils";
import { alertConfirmPromise } from "components/Alert";
import DailyJobDialogV3 from "../../DailyJobDialogV3";

let selectedId = null
const JOData = () => {

  const [dialogOpen, setDialogOpen] = useState(false)

  const { joData, driverFullName, joSelection, setJOSelection, setJOItemWithOld, setPCItemData, setPCSelection
    , pettyCashData, isJOChanged, getDataDefault }
    = useContext(JobOrderTransferDialogV2Context)

  const toggleContSts = useCallback((row) => () => { }, [])

  const showDetail = useCallback((params) => {
    selectedId = params.id
    setDialogOpen(true)
  }, [])

  const handleCellClick = useCallback((params) => {
    if (params.field === "ShowDetail") {
      showDetail(params)
    }
  }, [showDetail])


  const handleRowDoubleClick = useCallback((params) => {
    showDetail(params)
  }, [showDetail])

  const onDailyJobDialogFinish = useCallback(()=>{
    getDataDefault()
  }, [getDataDefault])

  const handleJORowClick = useCallback(async (params) => {
    // console.log('params', params)
    if (isJOChanged) {
      const result = await alertConfirmPromise({ message: "ข้อมูลใบงานมีการเปลี่ยนแปลง หากทำรายการต่อข้อมูลจะไม่ถูกบันทึก", okText: "ทำรายการต่อ" })
      if (!result) return
    }

    if (joSelection.length > 0 && joSelection[0] === params.id) {
      setJOSelection([])
      setJOItemWithOld({ ...initJOItem })
      setPCItemData([])
      setPCSelection([])
    } else {
      setJOSelection([params.id])
      setJOItemWithOld(params.row)
      setPCItemData(addIdForDataGrid([...(params.row.PCItems || [])], "PCItmId"))
      const found = pettyCashData.find(pc => pc.JobOrdId === params.id)
      if (found) {
        setPCSelection([found.PCTrnsId])
      } else {
        setPCSelection([])
      }
    }
  }, [setJOSelection, setJOItemWithOld, setPCItemData, joSelection, pettyCashData, setPCSelection, isJOChanged])

  const jobOrderColumnsMemo = useMemo(() => jobOrderColumns(toggleContSts), [toggleContSts])

  return (
    <BoxFC sx={{ gap: 1 }}>
      <BoxFR sx={{ alignItems: "baseline" }}>
        <Typography variant="h6">งานที่รับผิดชอบ</Typography>
        <Typography variant="body" >(จำนวน {joData.length} ใบงาน)</Typography>
        <Box flex={1} />
        <Typography variant="body">{driverFullName}</Typography>
      </BoxFR>
      <Box height={270} sx={rowHighlightOrange}>
        <DataGridCellExpand
          density="compact"
          disableSelectionOnClick={true}
          hideToolbar
          hideFooter
          selectionModel={joSelection}
          onSelectionModelChange={(ids) => setJOSelection(ids)}
          columns={jobOrderColumnsMemo}
          rows={joData}
          onRowClick={handleJORowClick}
          onRowDoubleClick={handleRowDoubleClick}
          onCellClick={handleCellClick}
        />
      </Box>
      <DailyJobDialogV3
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        selectedId={selectedId}
        onFinish={onDailyJobDialogFinish} />
    </BoxFC>
  );
}

export default React.memo(JOData);