import { Box, Dialog, DialogContent, Paper } from "@mui/material"
import { blueGrey } from "@mui/material/colors"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { jobOrderApiNm } from "constants/jobOrderConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState, useMemo, createContext } from "react"
import dayjs from "dayjs"
import { addIdForDataGrid } from "utils"
import PCTransaction from "./JobOrderTransferComponent/V2Component/PCTransaction"
import JOData from "./JobOrderTransferComponent/V2Component/JOData"
import PCItemsData from "./JobOrderTransferComponent/V2Component/PCItemsData"
import JOItem from "./JobOrderTransferComponent/V2Component/JOItem"
import { initJOItem } from "./initData"
import PettyCashDialog from "../PettyCash/PettyCashDialog"
import AddAdvanceDialog from "../PettyCash/AddAdvanceDialog"
import ComboBox from "components/ComboBox"

export const JobOrderTransferDialogV2Context = createContext()

const initFilterData = {
  ModDteSt: dayjs().format("YYYY-MM-DD 00:00:00"),
  ModDteEn: dayjs().format("YYYY-MM-DD 23:59:59"),
  OrderBy: "ModDte",
  OrderByDirection: "DESC"
}

export const focusJOCols = ["FinDte", "DrvId", "ArvTm", "LevTm", "TakeArvTm", "TakeLevTm", "ContNo", "SealNo", "FullWt", "TareWt", "Rmk"]

let lastDrvId = null
let lastJobOrdId = null

const selectedRow = null

let oJOItem = { ...initJOItem }

let selectedPCTrnsId = null

let dataHasChagne = false

let isFirstTimeSetAllData = true

const JobOrderTransferDialogV2 = ({ jobOrdId, initDrvId, dialogOpen, setDialogOpen, onFinish }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [joData, setJOData] = useState([])
  const [pettyCashData, setPettyCashData] = useState([])
  const [pettyCashHistoryData, setPettyCashHistoryData] = useState([])
  const [joSelection, setJOSelection] = useState([])
  const [pcSelection, setPCSelection] = useState([])
  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [pcItemData, setPCItemData] = useState([])
  const [joItem, setJOItem] = useState({ ...initJOItem })
  const [dialogPettyCashOpen, setDialogPettyCashOpen] = useState(false)
  const [dialogAddAdvanceOpen, setDialogAddAdvanceOpen] = useState(false)
  const [drvId, setDrvId] = useState(null)
  const [driverComboData, setDriverComboData] = useState([])

  const selectedDriver = useMemo(() => {
    // console.log('drvId', drvId)
    if (drvId) {
      return msData.drivers.find(d => d.DrvId === drvId)
    } else {
      return joData.length > 0 ? msData.drivers.find(d => d.DrvId === joData[0].DrvId) : null
    }
  }, [msData, drvId, joData])

  const advDialogArgsMemo = useMemo(() => ({ DrvId: drvId }), [drvId])

  const driverFullName = useMemo(() => selectedDriver ? `${selectedDriver?.FName} ${selectedDriver?.LName} (${selectedDriver?.NName})` : "- - (-)"
    , [selectedDriver])

  const isJOChanged = useMemo(() => {

    let result = false
    for (const col of focusJOCols) {
      if (oJOItem[col] !== joItem[col]) {
        result = true
        break
      }
    }
    return result
  }, [joItem])

  const setJOItemWithOld = useCallback((item) => {
    setJOItem(item)
    oJOItem = item
  }, [])

  const setAllData = useCallback((data, joId) => {
    if (isFirstTimeSetAllData) {
      setDriverComboData(msData.driverCombo.filter(drv => data.drvIds.includes(drv.id)))
    }
    lastDrvId = data.drvIds[0]
    setDrvId(data.drvIds[0])
    const localJOData = addIdForDataGrid(data.jobOrders, "JobOrdId")
    setJOData(localJOData)
    setPettyCashData(addIdForDataGrid(data.pettyCash, "PCTrnsId"))
    setPettyCashHistoryData(addIdForDataGrid(data.pettyCashHistory, "PCTrnsId"))
    setJOSelection(o => {
      console.log("in setJOSelection", jobOrdId, isFirstTimeSetAllData)
      if (joId) {
        const found = localJOData.find(jo => jo.JobOrdId === jobOrdId)
        console.log("found", found) 
        if (found) {
          setJOItemWithOld({ ...found })
          setPCItemData(addIdForDataGrid([...(found.PCItems || [])], "PCItmId"))
        }
        return [joId]
      } else if (o.length > 0) {
        const found = localJOData.find(jo => jo.JobOrdId === o[0])
        if (found) {
          setJOItemWithOld({ ...found })
          setPCItemData(addIdForDataGrid([...(found.PCItems || [])], "PCItmId"))
        } else {
          setJOItemWithOld({ ...initJOItem })
          setPCItemData([])
        }
        return o
      }
      return []
    })
    isFirstTimeSetAllData = false
  }, [jobOrdId, setJOItemWithOld, msData.driverCombo])

  const getData = useCallback((id, joId) => {
    const postData = {
      JobOrdId: joId,
      DrvId: id,
      ModDteSt: dayjs().format("YYYY-MM-DD 00:00:00"),
      ModDteEn: dayjs().format("YYYY-MM-DD 23:59:59"),
      getFn: "getJobOrderByDriver",
    }
    ax.post(jobOrderApiNm.getJobOrderByDriverV2, postData).then(value => {
      if (value.data) {
        setAllData(value.data, joId)
      }
    })
  }, [ax, setAllData])

  const getDataDefault = useCallback(()=>{
    getData(lastDrvId, jobOrdId)
  }, [jobOrdId, getData])

  const onFinishToJobOrderTransfer = useCallback((data) => {
    console.log("onJobOrderTrnsferFinish", data)
    dataHasChagne = true
    if (data) {

      setFilterData(o => ({
        ...o,
        ModDteSt: dayjs().format("YYYY-MM-DD 00:00:00"),
        ModDteEn: dayjs().format("YYYY-MM-DD 23:59:59")
      }))
      setAllData(data, lastJobOrdId)
    } else {
      console.log("=====================================================in onFinishToJobOrderTransfer getData")
      getData(lastDrvId, lastJobOrdId)
    }
  }, [setAllData, getData])

  const handleclose = useCallback(() => {
    if (dataHasChagne) onFinish()

    setDialogOpen(false)
  }, [onFinish, setDialogOpen])

  const showPCTrnsDetail = useCallback((row) => {
    selectedPCTrnsId = row.PrPCTrnsId || row.PCTrnsId
    setDialogPettyCashOpen(true)
  }, [])
  const handleShowPCTrnsDetail = useCallback((row) => (e) => {
    if (pcSelection.length > 0 && pcSelection[0] === row.PCTrnsId) {
      e.stopPropagation()
    }
    showPCTrnsDetail(row)
  }, [pcSelection, showPCTrnsDetail])

  const handleAddAdvance = useCallback(() => {
    selectedPCTrnsId = null
    setDialogAddAdvanceOpen(true)
  }, [])

  const handleChangeDrvId = useCallback((id) => {
    if (!id) return
    setDrvId(id)
    lastDrvId = id
    lastJobOrdId = null
    getData(id, null)
  }, [getData])
  useEffect(() => {
    if (dialogOpen) {
      lastDrvId = initDrvId
      getData(initDrvId, jobOrdId)
    } else {
      setPettyCashData([])
      setPettyCashHistoryData([])
      setJOSelection([])
      setPCSelection([])
      setJOData([])
      setJOItem({ ...initJOItem })
      oJOItem = { ...initJOItem }
      isFirstTimeSetAllData = true
    }
  }, [dialogOpen, getData, initDrvId, jobOrdId])

  // // console.log("JobOrderTransferDialogV2 render ---- last Filter ::", lastFilter)
  // console.log("driverExpenseSelection", driverExpenseSelection)

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 1850,
          // minHeight: 1000,
          // maxHeight: 1000,
        }
      }}
      sx={{ py: 0, "& .MuiTypography-root.MuiDialogTitle-root": { py: 1, display: "flex", alignItems: "center" } }}
    >
      <DialogHeader handleDialogClose={handleclose}
        title={`รับ-จ่ายใบงาน`}
        onDeleteClick={null}

      >
        <Box sx={{ mx: 1, p: 1, bgcolor: "white", borderRadius: 1 }}>
          <ComboBox sx={{ width: 150 }} options={driverComboData} label="พนักงานขับรถ"
            selectedId={drvId}
            setSelectedId={handleChangeDrvId}
          />
        </Box>
      </DialogHeader>
      <DialogContent sx={{ bgcolor: blueGrey[50], p: 1 }}>
        <JobOrderTransferDialogV2Context.Provider value={{
          joData, setJOData, pettyCashData, setPettyCashData
          , joSelection, setJOSelection, pcSelection, setPCSelection, filterData, setFilterData, pcItemData
          , setPCItemData, joItem, setJOItem, setJOItemWithOld, driverFullName, handleShowPCTrnsDetail, showPCTrnsDetail
          , onFinishToJobOrderTransfer, pettyCashHistoryData, setPettyCashHistoryData, oJOItem, selectedRow, drvId
          , handleAddAdvance, isJOChanged, getDataDefault
        }}>
          <BoxFR mt={1} height={740} sx={{ alignItems: "stretch" }}>
            <PCTransaction />
            <Paper sx={{ px: 1, py: 1, flex: 1 }}>
              <BoxFC sx={{ gap: 1, height: "100%" }}>
                <JOData />
                <BoxFR flex={1} height="100%">
                  <PCItemsData />
                  <JOItem />
                </BoxFR>
              </BoxFC>
            </Paper>
          </BoxFR>
        </JobOrderTransferDialogV2Context.Provider>

        <AddAdvanceDialog
          pcTrnsId={selectedPCTrnsId}
          dialogOpen={dialogAddAdvanceOpen}
          setDialogOpen={setDialogAddAdvanceOpen}
          onFinish={onFinishToJobOrderTransfer}
          getFn="getJobOrderByDriverV2"
          getArgs={advDialogArgsMemo}
          usrAccId={drvId}
          joData={joItem} />
        <PettyCashDialog
          selectedId={selectedPCTrnsId}
          dialogOpen={dialogPettyCashOpen}
          setDialogOpen={setDialogPettyCashOpen}
          onFinish={onFinishToJobOrderTransfer}
          lastFilter={null}
          getFn="getJobOrderByDriverV2"
          getArgs={advDialogArgsMemo} />
      </DialogContent>
      <DialogFooter okText={""} cancelText={"ปิด"}
        handleDialogClose={handleclose}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default JobOrderTransferDialogV2