import { ArticleRounded, PlaylistAddCheckCircleRounded, PriceCheck, PublishedWithChangesRounded } from "@mui/icons-material";
import { dailyExpenseColNm } from "constants/dailyExpenseConstant";
import dayjs from "dayjs";
import { numberFormat } from "utils";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { locationText } from "utils/locationText";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { BoxFR } from "components/BoxCustom";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LensIcon from '@mui/icons-material/Lens';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { IconButton, Tooltip, Typography } from "@mui/material"
import { pettyCashColNm } from "pages/Menu1/PettyCash/constant";
const baseExpenseColumn = [
  {
    field: "JobOrdId",
    headerName: dailyExpenseColNm.JobOrdId,
    width: 90,
    align: "center"
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    valueGetter: (params) => params.row.TakePOIId == null && params.row.LocPOIId == null && params.row.RtnPOIId === null ?
      "-" : locationText(params.row),
    width: 190,
  },
  {
    field: "ContSizeFull",
    headerName: dailyExpenseColNm.ContSizeFull,
    width: 60,
  },
  {
    field: "IsCash",
    headerName: dailyExpenseColNm.IsCash,
    width: 65,
    valueFormatter: (params) => params.value === 1 ? "เงินสด" : "เงินโอน",
  },
  {
    field: "Amnt",
    headerName: dailyExpenseColNm.Amnt,
    width: 80,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(parseFloat(params.value)) : "0",
  },
  {
    field: "Dscp",
    headerName: dailyExpenseColNm.Dscp,
    flex: 1
  },
]
export const dailyExpenseColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center",
  },
  {
    field: "ModDte",
    headerName: "วันที่ทำรายการ",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  ...baseExpenseColumn,
  {
    field: "ClrDte",
    headerName: "Clear",
    width: 40,
    align: "center",
    renderCell: (params) => <PlaylistAddCheckCircleRounded color={params.value ? "success" : "disabled"} />
  }

]

export const driverExpenseColumns = (handleCompleteExpense) => [
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center",
  },
  {
    field: "ExpDte",
    headerName: dailyExpenseColNm.ExpDte,
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  ...baseExpenseColumn,
  {
    field: 'actions',
    type: 'actions',
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<PlaylistAddCheckCircleRounded />}
        label="Clear"
        onClick={() => handleCompleteExpense(params.row)}
      />,
      // <GridActionsCellItem
      //   icon={<PlaylistAddCheckCircleRounded />}
      //   label="Clear"
      //   onClick={() => handleCompleteExpense(params.row)}
      // />
    ]
  }
]

export const jobOrderColumns = (toggleContSts) => [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center",
  },
  {
    field: "JobOrdId",
    headerName: jobOrderColNm.JobOrdId,
    width: 90,
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    type: 'dateTime',
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSize,
    width: 70,
  },
  {
    field: "Location",
    headerName: "สถานที่",
    valueGetter: (params) => locationText(params.row),
    flex: 1,
  },
  {
    field: "ContSts",
    headerName: "สถานะ",
    width: 120,
    renderCell: (params) => {
      const msData = JSON.parse(localStorage.getItem("msData"))
      const contStsesMaster = msData.containerStatuses.filter(sts => !sts.IsHide)
      const contStses = params.row.ContStses || []
      const contStsObj = contStses.reduce((prev, cur) => {
        prev[cur.ContStsId] = cur
        return prev
      }, {})
      return (
        <BoxFR sx={{ gap: 0 }}>
          {contStsesMaster.map(contSts => {
            const contStsId = contSts.ContStsId
            return contStsObj[contStsId] ?
              <IconButton key={contStsId} sx={{ p: 0 }} onClick={toggleContSts(contStsObj[contStsId])}>
                {contStsObj[contStsId].IsCmpt ?
                  <CheckCircleRoundedIcon color={contSts.BgClr} />
                  :
                  <LensIcon color={contSts.BgClr} />
                }
              </IconButton>
              :
              <PanoramaFishEyeIcon key={contStsId} color={contSts.BgClr} />
          })}
        </BoxFR>
      )
    }
  },
]

const basePCTrnsColumns = (handleShowPCTrnsDetail, hideModDte) => [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    minWidth: 0,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" onClick={handleShowPCTrnsDetail(params.row)}>
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    minWidth: 0,
    width: 40,
    align: "center"
  },
  {
    field: "ModDte",
    headerName: "แก้ไขล่าสุด",
    hide: hideModDte,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "PayTm",
    headerName: pettyCashColNm.PayTm,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "ExpNm",
    headerName: "รายการ",
    width: 130,
    valueGetter: ({ row, value }) => !value ? "-" : `${row.PrPCTrnsId ? row.InOutTyp === "I" ? "(เงินทอน)" : "(เงินเพิ่ม)" : ""}${row.ExpNm}`,
    renderCell: ({ value, row }) => (
      <BoxFR sx={{ gap: 0 }}>
        <Typography variant="body2">{value}</Typography>
        {row.Dscp || row.Rmk ?
          <Tooltip title={`${row.Dscp ? `(${row.Dscp})` : ""}${row.Rmk ? `(${row.Rmk})` : ""}`} placement="top" arrow>
            <Typography color="red">*</Typography>
          </Tooltip>
          : null
        }
      </BoxFR>
    )
  },
  // {
  //   field: "Dscp",
  //   headerName: pettyCashColNm.Dscp,
  //   width: 120,
  // },
  {
    field: "JobOrdId",
    headerName: pettyCashColNm.JobOrdId,
    width: 90,
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    valueGetter: ({ row }) => row.TakePlc || row.Loc || row.RtnPlc ? `${row.TakePlc}-${row.Loc}-${row.RtnPlc}` : "",
    flex: 1,
    minWidth: 150
  },
  // {
  //   field: "PCJnlNm",
  //   headerName: pettyCashColNm.PCJnlNm,
  //   width: 100,
  // },
  {
    field: "AmntRemain",
    headerName: "เงินจ่าย/เหลือ",
    width: 100,
    align: "right",
    valueGetter: ({ row }) => row.PrPCTrnsId ? +row.Amnt : `${+row.Amnt}/${+row.Amnt + +(row.ChdAmnt || 0) - +(row.ItmAmnt || 0)}`,
    valueFormatter: ({ value }) => value.toLocaleString(),
  },
]

export const pcTrnsColumns = ({ handleShowPCTrnsDetail, handleEditAdvance, handleAddItem, handleAddItemUseAll, handleReturnChange }) => [
  ...basePCTrnsColumns(handleShowPCTrnsDetail, true),
  {
    field: "x1",
    headerName: "ใช้พอดี",
    width: 55,
    align: "center",
    renderCell: ({ row }) => (
      <Tooltip title="ใช้เงินพอดีไม่มีเลขที่ใบเสร็จ" arrow placement="top">
        <IconButton sx={{ p: 0 }} onClick={handleAddItemUseAll(row)}>
          <PriceCheck color="primary" />
        </IconButton>
      </Tooltip>
    )
  },
  {
    field: "x2",
    headerName: "ทอน",
    width: 40,
    minWidth: 0,
    align: "center",
    renderCell: ({ row }) => (
      <Tooltip title="เงินเหลือทอนคืน" arrow placement="top">
        <IconButton sx={{ p: 0 }} onClick={handleReturnChange(row)}>
          <PublishedWithChangesRounded color="primary" />
        </IconButton>
      </Tooltip>
    )
  },
  // {
  //   field: "edit",
  //   headerName: "",
  //   width: 65,
  //   renderCell: ({ row }) => {
  //     const isDisableEdit = row.PCPrdId
  //       || (+(row.ChdAmnt || 0) !== 0)
  //       || (+(row.ItmAmnt || 0) !== 0)
  //     return (<BoxFR sx={{ gap: 0 }}>
  //       <Tooltip title="แก้ไข" arrow placement="top">
  //         <IconButton sx={{ p: 0 }} onClick={isDisableEdit ? null : handleEditAdvance(row)}>
  //           <EditRounded color={isDisableEdit ? "disabled" : "primary"} />
  //         </IconButton>
  //       </Tooltip>
  //       <Tooltip title="ใส่รายละอียดการใช้เงิน" arrow placement="top">
  //         <IconButton sx={{ p: 0 }} onClick={handleAddItem(row)}>
  //           <Receipt color="primary" />
  //         </IconButton>
  //       </Tooltip>
  //     </BoxFR>)
  //   }
  // }
]

export const pcTrnsHistoryColumns = (handleShowPCTrnsDetail) => [
  ...basePCTrnsColumns(handleShowPCTrnsDetail, false),
  {
    field: "IsCmpt",
    headerName: pettyCashColNm.IsCmpt,
    width: 50,
    align: "center",
    valueFormatter: (params) => params.value ? <Typography color="success">✔</Typography> : "",
  }
]

export const itmColumns = (handleShowPCTrnsDetail) => [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    minWidth: 0,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" onClick={handleShowPCTrnsDetail(params.row)}>
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "UsrNNm",
    headerName: "ผุ้ใช้เงิน",
    width: 80,
  },
  {
    field: "DocNo",
    headerName: "เลขที่เอกสาร",
    width: 90,
  },
  {
    field: "ExpNm",
    headerName: "รายการ",
    minWidth: 150,
    flex: 1,
    renderCell: ({ value, row }) => (
      <BoxFR sx={{ gap: 0 }}>
        <Typography variant="body2">{value}</Typography>
        {row.Dscp || row.Rmk ?
          <Tooltip title={`${row.Dscp ? `(${row.Dscp})` : ""}${row.Rmk ? `(${row.Rmk})` : ""}`} placement="top" arrow>
            <Typography color="red">*</Typography>
          </Tooltip>
          : null
        }
      </BoxFR>
    )
  },
  {
    field: "Amnt",
    headerName: "จำนวนเงิน",
    width: 100,
    align: "right",
    valueFormatter: ({ value }) => (+value).toLocaleString(),
  },
]