import { ArticleRounded,  EditRounded } from "@mui/icons-material"
import { IconButton, Typography } from "@mui/material"
import { pettyCashColNm } from "./constant"
import { numberFormat } from "utils"
import dayjs from "dayjs"

export const pettyCashColumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded  color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: "center"
  },
  {
    field: "UsrNNm",
    headerName: pettyCashColNm.UsrNNm,
    width: 100,
  },
  {
    field: "PayTm",
    headerName: pettyCashColNm.PayTm,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "ExpNm",
    headerName: pettyCashColNm.ExpNm,
    width: 180,
    valueGetter: ({ row, value }) => !value ? "-" : `${row.PrPCTrnsId ? row.InOutTyp === "I" ? "(เงินทอน)" : "(เงินเพิ่ม)" : ""}${row.ExpNm}`
  },
  {
    field: "Dscp",
    headerName: pettyCashColNm.Dscp,
    width: 120,
  },
  {
    field: "JobOrdId",
    headerName: pettyCashColNm.JobOrdId,
    width: 100,
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    valueGetter: ({ row }) => row.TakePlc || row.Loc || row.RtnPlc ? `${row.TakePlc}-${row.Loc}-${row.RtnPlc}` : "",
    width: 200,
  },
  {
    field: "PCJnlNm",
    headerName: pettyCashColNm.PCJnlNm,
    width: 100,
  },
  {
    field: "Amnt",
    headerName: pettyCashColNm.Amnt,
    width: 90,
    align: "right",
  },
  {
    field: "WhtPrct",
    headerName: pettyCashColNm.WhtPrct,
    width: 90,
    valueGetter: ({value}) =>  `${value||0}%` ,
    align: "right",
  },
  {
    field: "AmntOut",
    headerName: "เงินจ่าย",
    width: 100,
    align: "right",
    type: "number",
    valueGetter: ({ row }) => row.InOutTyp === "O" ? row.PayAmnt : "",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "AmntIn",
    headerName: "เงินรับ",
    width: 100,
    align: "right",
    type: "number",
    valueGetter: ({ row }) => row.InOutTyp === "I" ? row.PayAmnt : "",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "AdmAccNm",
    headerName: pettyCashColNm.AdmAccNm,
    width: 100,
  },
  {
    field: "PrIsCmpt",
    headerName: pettyCashColNm.IsCmpt,
    width: 50,
    align: "center",
    valueFormatter: (params) => params.value ? <Typography color="success">✔</Typography> : "",
    // renderCell: ({value})=> value===1? <CheckCircleRounded color="success"/>: ""
  },
  {
    field: "PCPrdId",
    headerName: "ปิดยอดแล้ว",
    width: 50,
    align: "center",
    valueFormatter: (params) => params.value ? <Typography color="success">✔</Typography> : "",
    // renderCell: ({value})=> value===1? <CheckCircleRounded color="success"/>: ""
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
]

const pcTransColumns = [...pettyCashColumns]
pcTransColumns.splice(0, 1,
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <EditRounded color={params.row.PrIsCmpt || params.row.PCPrdId ? "disabled" : "primary"} />
        </IconButton>
      )
    }
  })
export { pcTransColumns }

export const pcItemColumns = [

  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <EditRounded color={params.row.PrIsCmpt ? "disabled" : "primary"} />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: "center"
  },
  {
    field: "PCItmTm",
    headerName: pettyCashColNm.ItmDte,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "IsCost",
    headerName: "รายได้/ต้นทุน",
    width: 100,
    valueFormatter: ({ value }) => value === 1 ? "ต้นทุน" : value === 0 ? "รายได้" : "-",
    cellClassName: ({ value }) => value === 1 ? "cost" : value === 0 ? "income" : "xxx"
  },
  {
    field: "ExpNm",
    headerName: pettyCashColNm.ExpNm,
    width: 150,
    valueGetter: (params) => params.value || "-"
  },
  {
    field: "Dscp",
    headerName: pettyCashColNm.Dscp,
    width: 100,
  },
  {
    field: "DocNo",
    headerName: pettyCashColNm.DocNo,
    width: 100,
  },
  {
    field: "DocDte",
    headerName: pettyCashColNm.DocDte,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-";
    },
  },
  {
    field: "JobOrdId",
    headerName: pettyCashColNm.JobOrdId,
    width: 90,
  },
  {
    field: "Loc",
    headerName: pettyCashColNm.Loc,
    width: 150,
  },
  {
    field: "AdmAccNm",
    headerName: pettyCashColNm.AdmAccNm,
    width: 80,
  },
  {
    field: "Amnt",
    headerName: pettyCashColNm.Amnt,
    width: 90,
    align: "right",
  },
  {
    field: "WhtPrct",
    headerName: pettyCashColNm.WhtPrct,
    width: 90,
    valueGetter: ({value}) =>  `${value||0}%` ,
    align: "right",
  },
  {
    field: "PayAmnt",
    headerName: pettyCashColNm.PayAmnt,
    width: 90,
    align: "right",
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 100,
  },
]

export const pettyCashForBalSelectColumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return params.id === 0 ? null : (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: "center"
  },
  {
    field: "UsrNNm",
    headerName: pettyCashColNm.UsrNNm,
    width: 100,
  },
  {
    field: "PayTm",
    headerName: pettyCashColNm.PayTm,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "ExpNm",
    headerName: pettyCashColNm.ExpNm,
    width: 180,
    valueGetter: ({ row, value }) => !value ? "-" : `${row.PrPCTrnsId ? row.InOutTyp === "I" ? "(รับเงิน)" : "(เงินเพิ่ม)" : ""}${row.ExpNm}`
  },
  {
    field: "Dscp",
    headerName: pettyCashColNm.Dscp,
    width: 120,
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    valueGetter: ({ row }) => row.TakePlc || row.Loc || row.RtnPlc ? `${row.TakePlc}-${row.Loc}-${row.RtnPlc}` : "",
    width: 200,
  },
  {
    field: "AmntOut",
    headerName: "เงินจ่าย",
    width: 100,
    align: "right",
    type: "number",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "AmntIn",
    headerName: "เงินรับ",
    width: 100,
    align: "right",
    type: "number",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "AdmNNm",
    headerName: pettyCashColNm.AdmNNm,
    width: 100,
  },
]

export const pcPeriodColumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
    return (
      <IconButton size="small" >
      <ArticleRounded color="primary"/>
      </IconButton>
    )}
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "PCJnlNm",
    headerName: "บัญชี",
    width: 80,
  },
  {
    field: "AdmAccNm",
    headerName: "ผู้ทำรายการ",
    width: 100,
  },
  {
    field: "PCPrdTm",
    headerName: "เวลาสรุปยอด",
    width: 140,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY HH:mm") : "-";
    },
  },
  {
    field: "NumAll",
    headerName: "จำนวนรายการ",
    width: 90,
    align: 'center',
  },
  {
    field: "SumOut",
    headerName: "รวมยอดเงิน จ่าย",
    width: 110,
    valueFormatter: ({value})=> value?numberFormat(+value): "0",
    align: 'right',
  },
  {
    field: "SumIn",
    headerName: "รวมยอดเงิน รับ",
    width: 110,
    valueFormatter: ({value})=> value?numberFormat(+value): "0",
    align: 'right',
  },
  {
    field: "Bal",
    headerName: "ยอดคงเหลือ",
    width: 110,
    valueFormatter: ({value})=> value?numberFormat(+value): "0",
    align: 'right',
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
]