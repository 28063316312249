import { PersonSearchRounded } from "@mui/icons-material";
import { Button, TextField, Typography } from "@mui/material";
import { BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import React, { useCallback, useContext, useState } from "react";
import JobOrderTransferDialogV2 from "./JobOrderTransferDialogV2";
import { alertError } from "components/Alert";
import { GlobalStateContext } from "contexts/GlobalStateContext";

let selectedJobOrIdJobOrder = null
let selectedDrvIdJobOrder = null

const JobOrderTransferOpenner = ({lastFilter, onFinish}) => {
  const { msData } = useContext(GlobalStateContext)
  const [jobOrdIdJobOrder, setJobOrdIdJobOrder] = useState("")
  const [drvIdJobOrder, setDrvIdJobOrder] = useState("")
  const [dialogJobOrderTransferOpen, setDialogJobOrderTransferOpen] = useState(false)

  
  const handleOpenJobOrderTransfer = useCallback(() => {
    // console.log("handleOpenJobOrderTransfer::", drvIdJobOrder, jobOrdIdJobOrder)
    if(!jobOrdIdJobOrder && !drvIdJobOrder){
      alertError("กรุณาระบุเลขที่ใบงาน หรือ พนักงานขับรถ")
      return
    } else {
      if(drvIdJobOrder){
        setJobOrdIdJobOrder(null)
        selectedJobOrIdJobOrder = null
        setDialogJobOrderTransferOpen(true)
      } else {
        setDialogJobOrderTransferOpen(true)
      }
    }
  }, [drvIdJobOrder, jobOrdIdJobOrder])
  

  const handleJobOrdIdJobOrderKeyUp = useCallback((e) => {
    if (e.key === "Enter") {
      setDrvIdJobOrder(null)
      selectedDrvIdJobOrder = null
      setDialogJobOrderTransferOpen(true)
    }
  }, [])

  const handleJobOrdIdChange = useCallback((e)=>{
    setJobOrdIdJobOrder(e.target.value)
    selectedJobOrIdJobOrder = e.target.value
  }, [])

  const handleSelecteDrvId = useCallback((drvId) => {
    setDrvIdJobOrder(drvId)
    selectedDrvIdJobOrder = drvId
  }, [])

  return (
    <BoxFR sx={{ border: "1px solid lightgrey", borderRadius: 1.5, bgcolor: "white", mt: 1 }} position="relative" p={1} >
      <Typography variant="overline" position="absolute" top={-16} bgcolor="white" px={1} >เคลียเงิน-ลงจบ</Typography>
      <TextField sx={{ width: 110 }} size="small" label="เลขที่ใบงาน" type="number"
        value={jobOrdIdJobOrder || ""} onChange={handleJobOrdIdChange}
        onKeyUp={handleJobOrdIdJobOrderKeyUp} />
      <ComboBox options={msData.driverOnlyCombo} label="พนักงานขับรถ" sx={{ width: 120 }}
        selectedId={drvIdJobOrder}
        setSelectedId={handleSelecteDrvId} />
      <Button variant="contained" sx={{ minWidth: 0, p: 1 }} onClick={handleOpenJobOrderTransfer}>
        <PersonSearchRounded />
      </Button>


      <JobOrderTransferDialogV2
        jobOrdId={selectedJobOrIdJobOrder}
        initDrvId={selectedDrvIdJobOrder}
        // drvId={state.drvIdJobOrder}
        dialogOpen={dialogJobOrderTransferOpen}
        setDialogOpen={setDialogJobOrderTransferOpen}
        onFinish={onFinish}
        lastFilter={lastFilter} />
    </BoxFR>
  )
}

export default React.memo(JobOrderTransferOpenner);