import React, { useContext, useCallback, useMemo, useState } from "react";
import { JobOrderTransferDialogV2Context } from "../../JobOrderTransferDialogV2";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { Box, Button, Paper, Typography } from "@mui/material";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import DateTimeSelect from "components/DateTimeSelect";
import { pcTrnsColumns, pcTrnsHistoryColumns } from "../columns";
import { addIdForDataGrid, numberFormat } from "utils";
import { initJOItem, rowHighlightOrange } from "../../initData";
import { pettyCashApiNm } from "pages/Menu1/PettyCash/constant";
import dayjs from "dayjs";
import { UserContext } from "contexts/UserContext";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { SearchRounded } from "@mui/icons-material";
import { alertConfirmPromise } from "components/Alert";
import ChangeDialog from "pages/Menu1/PettyCash/ChangeDialog";
import ReimbursementDialog from "pages/Menu1/PettyCash/ReimbursementDialog";

let selectedRow = null

let initChangeDialogData = null

const PCTransaction = () => {
  const [dialogChangeOpen, setDialogChangeOpen] = useState(false)
  const [dialogReimbursementOpen, setDialogReimbursementOpen] = useState(false)
  const { ax } = useContext(GlobalStateContext)
  const { user } = useContext(UserContext)
  const { pettyCashData, driverFullName, pcSelection, setPCSelection, handleShowPCTrnsDetail, joData, setJOSelection
    , filterData, setFilterData, pettyCashHistoryData, handleAddAdvance, drvId, onFinishToJobOrderTransfer
    , setJOItemWithOld, setPCItemData, setPettyCashHistoryData, isJOChanged, showPCTrnsDetail }
    = useContext(JobOrderTransferDialogV2Context)

  const drvIdObjMemo = useMemo(() => ({ DrvId: drvId }), [drvId])

  const memoDrvIdArgs = useMemo(() => ({ DrvId: drvId }), [drvId])

  const sumDriverExpense = useMemo(() => {
    return pettyCashData.reduce((prev, cur) => prev + ((+cur.Amnt) * (cur.InOutTyp === "I" ? -1 : 1)) - +(cur.ChdAmnt || 0) - +(cur.ItmAmnt || 0), 0)
  }, [pettyCashData])

  const getPCTrnsHistory = useCallback((filter) => {
    ax.post(pettyCashApiNm.getPettyCashHistory, filter).then(value => {
      if (value.data) {
        setPettyCashHistoryData(addIdForDataGrid(value.data, "PCTrnsId"))
      }
    })
  }, [ax, setPettyCashHistoryData])

  const handleAddItemUseAll = useCallback((row) => (e) => {
    if (pcSelection.length > 0 && pcSelection[0] === row.PCTrnsId) {
      e.stopPropagation()
    }
    const postData = {
      PCItmTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      ExpId: row.ExpId,
      Amnt: row.Amnt,
      JobOrdId: row.JobOrdId,
      AdmAccId: user.AccId,
      IsCost: row.IsCost,
      PCTrnsId: row.PCTrnsId,
      getFn: "getJobOrderByDriverV2",
      getArgs: { DrvId: drvId }
    }
    ax.post(pettyCashApiNm.insertPCItem, postData).then(value => {
      if (value.data) {
        onFinishToJobOrderTransfer(value.data)
        setPCSelection([])
      }
    })
  }, [onFinishToJobOrderTransfer, ax, user, drvId, pcSelection, setPCSelection])

  const handleReturnChange = useCallback((row) => (e) => {
    if (pcSelection.length > 0 && pcSelection[0] === row.PCTrnsId) {
      e.stopPropagation()
    }
    selectedRow = row
    const diffAmount = +row.Amnt + +(row.ChdAmnt ||0) - +(row.ItmAmnt || 0)
    initChangeDialogData = {
      ...row,
      PayTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      InOutTyp: diffAmount > 0 ? "I" : "O",
      Dscp: diffAmount > 0 ? "เงินทอน" : "เงินเพิ่ม",
      Amnt: Math.abs(diffAmount),
      PrPCTrnsId: row.PCTrnsId,
    }
    setDialogChangeOpen(true)
  }, [pcSelection])

  const handlePCRowClick = useCallback(async (params) => {
    console.log('params', params)
    if (isJOChanged) {
      const result = await alertConfirmPromise({ message: "ข้อมูลใบงานมีการเปลี่ยนแปลง หากทำรายการต่อข้อมูลจะไม่ถูกบันทึก", okText: "ทำรายการต่อ" })
      if (!result) return
    }
    // setPCSelection([])
    if (pcSelection.length > 0 && params.id === pcSelection[0]) {
      setPCSelection([])
      setJOSelection([])
      setJOItemWithOld({ ...initJOItem })
    } else {
      setPCSelection([params.id])
      if (params.row.JobOrdId) {
        const found = joData.find(jo => jo.JobOrdId === params.row.JobOrdId)
        if (found) {
          setJOSelection([found.JobOrdId])
          setJOItemWithOld({ ...found })
          setPCItemData(addIdForDataGrid([...(found.PCItems || [])], "PCItmId"))
        } else {
          setJOSelection([])
          setJOItemWithOld({ ...initJOItem })
          setPCItemData([])
        }
      } else {
        setJOSelection([])
        setJOItemWithOld({ ...initJOItem })
        setPCItemData([])
      }
    }

  }, [pcSelection, setPCSelection, joData, setJOSelection, setJOItemWithOld, setPCItemData, isJOChanged])

  const handleAddReimbursement = useCallback(() => {
    setDialogReimbursementOpen(true)
  }, [])

  const handlePCRowDoubleClick = useCallback((params) => {
    // selectedPCTrnsId = params.id
    showPCTrnsDetail(params.row)
  }, [showPCTrnsDetail])


  const pcTransColumnsMemo = useMemo(() => pcTrnsColumns({ handleShowPCTrnsDetail, handleAddItemUseAll, handleReturnChange })
    , [handleShowPCTrnsDetail, handleAddItemUseAll, handleReturnChange])

  const pcTransHistoryColumnsMemo = useMemo(() => pcTrnsHistoryColumns(handleShowPCTrnsDetail)
    , [handleShowPCTrnsDetail])


  // console.log("pcSelection", pcSelection)
  return (
    <BoxFC sx={{ gap: 1 }} height="100%">
      <Paper sx={{ px: 1, py: 1, width: 900, flex: 1 }} >
        <BoxFC height="100%" sx={{ gap: 1 }}>
          <BoxFR sx={{ alignItems: "baseline" }}>
            <Typography variant="h6" >เงินค้าง</Typography>
            <Typography variant="body" >({pettyCashData.length} รายการ รวม {numberFormat(sumDriverExpense)} บาท)</Typography>
            <Button variant="contained" size="small" onClick={handleAddAdvance}>เพิ่มเงินล่วงหน้า</Button>
            <Button variant="contained" size="small" onClick={handleAddReimbursement}>เบิกเงิน(ไม่มีใบเสร็จ)</Button>
            <Box flex={1} />
            <Typography variant="body">{driverFullName}</Typography>
          </BoxFR>
          <Box width="100%" height="100%" sx={rowHighlightOrange}>
            <DataGridCellExpand
              density="compact"
              disableSelectionOnClick={true}
              hideToolbar
              hideFooter
              rows={pettyCashData}
              selectionModel={pcSelection}
              onSelectionModelChange={(ids) => setPCSelection(ids)}
              columns={pcTransColumnsMemo}
              onRowClick={handlePCRowClick}
              onRowDoubleClick={handlePCRowDoubleClick}
            />
          </Box>
        </BoxFC>
      </Paper>

      <Paper sx={{ px: 1, py: 1, width: 900, flex: 1 }} >
        <BoxFC sx={{ gap: 1 }} height="100%">
          <BoxFR>
            <Typography variant="h6" >รายการ รับ-จ่าย เงินย้อนหลัง</Typography>
            <DateTimeSelect
              sx={{ width: 180 }}
              label=""
              start={filterData.ModDteSt}
              end={filterData.ModDteEn}
              setSelectDate={(st, en) => { setFilterData(o => ({ ...o, ModDteSt: st, ModDteEn: en })) }}
              getData={() => { getPCTrnsHistory(filterData) }}
              onRowDoubleClick={handlePCRowDoubleClick}
            />
            <Button variant="contained" onClick={() => getPCTrnsHistory(filterData)}><SearchRounded /></Button>
            <Box flex={1} />
            <Typography variant="body">{driverFullName}</Typography>
          </BoxFR>
          <Box width="100%" height="100%">
            <DataGridCellExpand
              density="compact"
              hideToolbar
              hideFooter
              rows={pettyCashHistoryData}
              columns={pcTransHistoryColumnsMemo}
              onRowDoubleClick={handlePCRowDoubleClick}
            />
          </Box>
        </BoxFC>
      </Paper>

      <ChangeDialog
        initData={initChangeDialogData}
        pcTrnsId={null}
        prPCTrnsId={selectedRow?.PCTrnsId}
        prPCTrnsData={selectedRow}
        dialogOpen={dialogChangeOpen}
        setDialogOpen={setDialogChangeOpen}
        onFinish={onFinishToJobOrderTransfer}
        isFromJobOrder={false}
        getFn="getJobOrderByDriverV2"
        getArgs={memoDrvIdArgs} />
      
      <ReimbursementDialog
        dialogOpen={dialogReimbursementOpen}
        setDialogOpen={setDialogReimbursementOpen}
        onFinish={onFinishToJobOrderTransfer}
        getFn= "getJobOrderByDriverV2"
        getArgs={drvIdObjMemo} 
        usrAccId={drvId}/>
    </BoxFC>
  )
}

export default React.memo(PCTransaction)